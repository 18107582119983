import { useEffect, useState } from "react";
import homeSectionData from '../../assets/json/homePageData.json';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Common from "../Common";

function Blog() {
  const [blogData, setBlogData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("All Topics");
  const cardsPerPage = 6;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };

  const getUsersData = async () => {
    try {
      const response = await axios.get(
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblog.tursio.ai%2Ffeed"
      );
      setBlogData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardClick = (link) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    getUsersData();
    setCurrentPage(1);
  }, []);

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;

  const items = blogData?.items
    ?.sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate))
    .map((item) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(item.description, "text/html");
      const description = Array.from(doc.querySelectorAll("h3")).map(h3 => h3.innerText).join(" ").split(" ").slice(0, 50).join(" ") + "...";
      return {
        author: item?.author,
        pubDate: item?.pubDate
          ? new Date(item.pubDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "",
        title: item?.title,
        link: item?.link,
        description: description,
        imgUrl: doc.querySelector("figure>img")?.src,
        categories: item?.categories
      };
    });

  const categories = ["All Topics", ...new Set(items?.flatMap(item => item.categories))];
  const totalPages = Math.ceil(items?.length / cardsPerPage);

  const filteredItems = items?.filter(item => 
    (selectedCategory === "All Topics" || item.categories.includes(selectedCategory)) &&
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentCards = filteredItems?.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-page">
      <Common />
      <div className="blog-slider-section">
        <div className="container">
          <div className="theme-title text-center">
            <h2>
              <span>Tursionic Blog</span>
            </h2>
          </div>
        </div>
      </div>

      <section className="blog-body-section">
        <div className="container">
          <div className="d-flex">
            <div className="blog-nav-body">
              <div className="row justify-content-end">
                <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-end">
                  <div className="blog-search">
                    <img src="image/blog-search.svg" alt="" />
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="mobile-blog-dropdown">
                <div className="dropdown">
                  <a className="btn dropdown-toggle blog-dropdown" href="#" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedCategory}
                    <img src="image/down-arrow.svg" />
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {categories.map((category, index) => (
                      <li key={index}>
                        <a className="dropdown-item" href="#" onClick={() => { setSelectedCategory(category); setCurrentPage(1); }}>{category}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row">
                    {currentCards?.map((item, index) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 mb-3"
                          key={index}
                        >
                          <div className="blog-tab-card" onClick={() => handleCardClick(item.link)}>
                            <img
                              className="w-100"
                              src={item?.imgUrl}
                              alt=""
                            />
                            <div className="blog-contant">
                              <div className="blog-date">
                                <span>{item?.author}</span>
                                <span>{item?.pubDate}</span>
                                <span>Published in Tursionic</span>
                              </div>
                              <div className="blog-title">
                                <h3>{item?.title}</h3>
                              </div>
                              <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="pagination-body research-pagination">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page ${currentPage === 1 ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link me-4 previous-next-btn"
                      onClick={() => paginate(currentPage - 1)}
                    >
                      {" "}
                      <img src="image/pagination-left-arrow.svg" alt="" />
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page ${index + 1 === currentPage ? "active" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page ${currentPage === totalPages ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link ms-4 previous-next-btn"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      {" "}
                      Next{" "}
                      <img
                        src="image/pagination-right-arrow.svg"
                        alt=""
                        className=""
                      />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="questions-section">
        <div className="container">
          <div className="questions-card">
            <div className="row justify-content-between align-items-center position-relative">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="content">
                  <div className="me-4">
                    <img
                      src={`${process.env.PUBLIC_URL}/image/${homeSectionData?.UnlimitedUser?.imageFileName}.svg`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="theme-title">
                      <h2 className="text-white">
                        <span> {homeSectionData?.UnlimitedUser?.title}</span>
                        <br />
                        {homeSectionData?.UnlimitedUser?.subTitle}
                      </h2>
                      <p className="mb-0 mt-2 text-white">
                        {homeSectionData?.UnlimitedUser?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="questions-btn">
                  <button type="button" className="btn btn-outline-light ms-3" onClick={handleClick}>
                    {homeSectionData?.UnlimitedUser?.requestDemoBtn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blog;
