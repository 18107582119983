import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const menuToggleRef = useRef(null);

  const handleClick = () => {
    navigate('/contact');
  };

  const handleNavLinkClick = () => {
    if (menuToggleRef.current) {
      menuToggleRef.current.checked = false;
    }
  };

  return (
    <div className="theme-header">
      <div className="container">
        <div className="header-container">
          <div className="logo-container">
            <Link to="/" className="navbar-brand">
              <img src="image/logo.png" alt="Tursio Logo" />
            </Link>
          </div>
          <nav className="ms-auto">
            <input ref={menuToggleRef} className="hidden" type="checkbox" id="menuToggle" />
            <label className="menu-btn" htmlFor="menuToggle">
              <img className="open-menu" src="image/manu.svg" alt="Open Menu" onError={(e) => e.target.style.display = 'none'} />
              <img className="close-menu" src="image/close-menu.svg" alt="Close Menu" onError={(e) => e.target.style.display = 'none'} />
            </label>
            <div className="nav-container theme-navbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={handleNavLinkClick}>
                    <img className="arrow-icon" src="image/mobile-sidebar-arrow-icon.svg" alt="Arrow Icon" onError={(e) => e.target.style.display = 'none'} /> Product
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/research" className="nav-link" onClick={handleNavLinkClick}>
                    <img className="arrow-icon" src="image/mobile-sidebar-arrow-icon.svg" alt="Arrow Icon" onError={(e) => e.target.style.display = 'none'} /> Research
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/blog" className="nav-link" onClick={handleNavLinkClick}>
                    <img className="arrow-icon" src="image/mobile-sidebar-arrow-icon.svg" alt="Arrow Icon" onError={(e) => e.target.style.display = 'none'} /> Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link" onClick={handleNavLinkClick}>
                    <img className="arrow-icon" src="image/mobile-sidebar-arrow-icon.svg" alt="Arrow Icon" onError={(e) => e.target.style.display = 'none'} /> About
                  </Link>
                </li>
                <li className="nav-item">
                  <button type="button" className="btn btn-outline-dark ms-3" onClick={handleClick}>
                    Request demo
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
